































// CORE
import { Component, Vue } from 'vue-property-decorator'
import { MetaInfo } from 'vue-meta'

// COMPONENT
import IntermarkLabel from '@/components/IntermarkLabel.vue'
import AuthFormWrapper from '@/components/AuthFormWrapper.vue'
import NotFound from '@/components/NotFound.vue'

@Component({
  components: {
    IntermarkLabel,
    AuthFormWrapper,
    NotFound,
  },
})
export default class ErrorPage extends Vue {
  private metaInfo (): MetaInfo {
    return {
      title: 'NotFound',
    }
  }
}
